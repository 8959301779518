body{
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.hide{ display: none; }

.layout{
  max-width: 1180px;
  margin:0 auto;
}

.header{ 
  margin-top:50px;
  display: flex;
  justify-content:space-between;
}
.header .navbar{
  color:white;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header .navbar .navbar-item{
  color:white;
  font-size: 16px;
  margin-right: 20px;
}
.header .navbar .navbar-logo{
  color:white;
  font-size: 16px;
  margin-right: 20px;
  align-self:baseline;
}


.header .logo{
  width:120px;

  border-radius: 50%;
  transition: transform 1s ease-out;
}
.header .logo:hover{
  transform: rotateZ(360deg);
  cursor: pointer;
} 


.preview{
  width:300px;
  height:300px;
  border:solid 1px #000;
  border-radius: 10px;
}

.preview-mini{
  width:160px;
  border:solid 1px #000;
  border-radius: 10px;
}


h1{
  color:white;
}


h2.title{
  color:#fcc801;
  font-size: 30px;
  font-weight: 700;
}
h3.title{
  color:#fff;
  font-size: 18px;
  font-weight: 500;
}

p.text{
  color:white;
  font-size:16px;
  /* text-indent: 2em; */
}



.loop{
  background:#fcc801;
  height:48px;
  margin-top: 50px ;
  margin-bottom: 50px ;
  border-top: 1px solid #fcc801;
  border-bottom: 1px solid #fcc801;
}
.loop .loop-warn{
  width:22px;
  margin-right: 10px;
  margin-left: 10px;
}
.loop .loop-desc{
  text-align: center;
  color:black;
  font-weight: 700;
  font-size:16px;
  white-space:nowrap;
}
.loop .loopText{
  font-size:16px;
  display:flex; 
}
.loop .loopText .loopText-content{
  color:black;
  white-space:nowrap;
  flex-direction:row;
  display:flex;
  flex:1;
  
}
.loop .loopText .loopText-content p{
  color:black;
  white-space:nowrap;
}

 

.banner{
  min-height: 270px;
  text-align: center;
  margin-top:120px;
}
.banner img{
  width:100%;
}


.warn {
  background-color:#fcc801;
  display:flex;
  justify-content:center;
  margin-top:40px;
  margin-bottom:40px; 
}
.warn .warn-desc{ 
  text-align: center;
  color:black;
  font-weight: 700;
  font-size:16px;
  padding:10px 0;
  white-space:nowrap;
}
.warn  .desc-warn{ 
  width:22px;
  margin-right: 10px;
  margin-left: 10px;
}



.about{
  min-height: 170px;
}
/* about-info */
.about .about-info{
  display:flex;
  flex-direction: row;
  justify-content:space-between;
}
.about .about-info .about-left{
  flex:7;
}
.about .about-info .about-side{
  flex:3;
  justify-content:flex-end;

}

.about .about-info .about-side ul{
  width:360px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:space-between;
}

.about .about-info .about-side ul li{
  width: 160px; 
  margin:10px;
}


/* about-sale */
.about .about-sale{
  display:flex;
  flex-direction: row;
  justify-content:space-between;
}
.about .about-sale .about-left{
  flex:4;
}
.about .about-sale .about-left img{ 
  margin:10px;
}
.about .about-sale .about-side{
  flex:7;
  justify-content:flex-end;

}
 
/* about-buy */
.about .about-buy{
  display:flex;
  flex-direction: row;
  justify-content:space-between;
}
.about .about-buy .about-left{
  flex:7;
  margin-right: 100px;
}
.about .about-buy .about-left img{ 
  margin:10px;
}
.about .about-buy .about-side{
  flex:3;
  /* justify-content:flex-end; */
  display:flex;
  justify-content: center; /* 水平居中 */
  align-items: center;     /* 垂直居中 */
}
 



.slogan {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content:flex-end;
  align-items: center;     /* 垂直居中 */

  background:#fcc801;
  height:48px;
  margin-top: 50px ;
  margin-bottom: 50px ;
  border-top: 1px solid #fcc801;
  border-bottom: 1px solid #fcc801;

}


.slogan .slogan-words {
  flex: 1;
  display: inline-block;
  font-size: 16px; 
  position: absolute;
  overflow:-webkit-marquee;/* 只有设置为marquee才有滚动效果 */ 
  color:black;
  font-weight: 500;
  white-space: nowrap;
  animation: sloganAnimation 30s linear infinite;
}

.slogan .slogan-words .slogan-desc{ 
  text-align: center;
  color:black;
  font-weight: 700;
  font-size:16px;
  padding:5px 0;
  white-space:nowrap;
}

.slogan .slogan-words .slogan-warn{ 
  width:22px;
  margin-right: 10px;
  margin-left: 10px;
}





@keyframes sloganAnimation {
  0% {
      left: 100%;
  }
  100% {
      transform: translateX(-100%);
      left: 0;
  }
}



.mint{
  height: 700px;
}




.loopSwiper{
  height:400px;
}

.loopSwiper .swiper{
  height:400px;
}
.loopSwiper .swiper .swiper-wrapper {
  height:400px;
}

.loopSwiper .swiper .swiper-wrapper .swiper-slide {
  height:400px;
}

.loopSwiper .swiper .swiper-wrapper .swiper-item{
  width:270px;
  border-radius:10px;
}


.faq{
  min-height: 200px;
}

.faq .faq-accordion{
  background-color: transparent;
}

.faq .faq-title{
  color: #fff;
  font-size:20px;
}
.faq .faq-text{
  color: #fcc801;
  font-size:16px;
}
.faq .faq-text .faq-link,
.faq .faq-text .faq-link:link,
.faq .faq-text .faq-link:hover,
.faq .faq-text .faq-link:active,
.faq .faq-text .faq-link:visited{
  color: #fcc801;
  font-size:16px;
  font-weight: bold;
  cursor: pointer;
  margin-left: 6px;
}
 


.footer{
  border-top: solid 1px #ccc;
  margin-top:20px;
  padding-top:20px;
  padding-bottom:120px;


  color:white;
  font-size: 16px; 


  margin-top:30px;
  display: flex;
  justify-content:space-between;
}

 
.footer .navbar{
  color:white;
  display: flex;
  flex-direction: row;
}

.footer .navbar .navbar-item{
  color:white;
  font-size: 16px;
  margin-right: 20px;
}











/* ---------Mobile----------- */

.mobile {
  width: 100%;
}

.mobile .layout{ 
  padding:0 1rem;
}

/* ---------Mobile-Header---------- */
.mobile .header{ 

  margin-top:50px;
  display: flex;
  flex-direction:column;
  justify-content:space-between;

}
.mobile .header .navbar{
  color:white;
  flex-direction: row;
  display: none;
}
.mobile .header .navbar .navbar-item{
  color:white;
  font-size: 16px;
  margin-right: 20px;
}

.mobile .preview{
  width:300px;
  height:300px;
  border:solid 1px #000;
  border-radius: 10px;
}

.mobile .preview-mini{
  width:160px;
  border:solid 1px #000;
  border-radius: 10px;
}



.mobile .banner{
  min-height: 270px;
  text-align: center;
  margin-top:120px; 
}

.mobile .banner img{
  min-height: 270px;
  text-align: center;
  margin-top:120px;
  width: 100%;
}




/* ---------Mobile-About---------- */
.mobile .about{
  min-height: 170px;
}
/* about-info */
.mobile .about .about-info{
  display:flex;
  flex-direction:column;
  justify-content:space-between;
}
.mobile .about .about-info .about-left{
  flex:1;
}
.mobile .about .about-info .about-side{
  flex:1;
  justify-content:flex-end;

}

.mobile .about .about-info .about-side ul{
  width:100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:space-between;
  margin:0;
  padding:0;
}

.mobile .about .about-info .about-side ul li{
  width: 48%; 
  margin:1%;
}


/* about-sale */
.mobile .about .about-sale{
  display:flex;
  flex-direction:column-reverse;
  justify-content:space-between;
}
.mobile .about .about-sale .about-left{
  flex:1;
  display:flex;
  justify-content: center; /* 水平居中 */
  align-items: center;     /* 垂直居中 */
}
.mobile .about .about-sale .about-left img{ 
  margin:10px;
}
.mobile .about .about-sale .about-side{
  flex:1;
  justify-content:flex-end;

}
 
/* about-buy */
.mobile .about .about-buy{
  display:flex;
  flex-direction:column;
  justify-content:space-between;
}
.mobile .about .about-buy .about-left{
  flex:1;
  width: 100%;
  margin-right: 0;
}
.mobile .about .about-buy .about-left img{ 
  margin:10px;
}
.mobile .about .about-buy .about-side{
  flex:1;
  /* justify-content:flex-end; */
  display:flex;
  justify-content: center; /* 水平居中 */
  align-items: center;     /* 垂直居中 */
}
 









/* ---------Mobile-slogan---------- */
.slogan {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content:flex-end;
  align-items: center;     /* 垂直居中 */


  background:#fcc801;
  height:48px;
  margin-top: 50px ;
  margin-bottom: 50px ;
  border-top: 1px solid #fcc801;
  border-bottom: 1px solid #fcc801;

}
.slogan .slogan-words {
  font-size: 16px;
  margin-left: 20rpx;
  position: absolute;
  overflow:-webkit-marquee;/* 只有设置为marquee才有滚动效果 */
  top: 0rpx;
  color:black;
  font-weight: 500;
  white-space: nowrap;
  animation: sloganAnimation 30s linear infinite;
}




.mobile .mint{
  height: 700px;
}
.mobile .faq{
  min-height: 200px;
}

.mobile .faq .faq-accordion{
  background-color: transparent;
}

.mobile .faq .faq-title{
  color: #fff;
  font-size:20px;
}
.mobile .faq .faq-text{
  color: #fcc801;
  font-size:16px;
}

.mobile .faq img.roadmap{ 
  width: 100%;
}




.mobile .footer{
  border-top: solid 1px #ccc;
  margin-top:20px;
  padding-top:20px;
  padding-bottom:120px;


  color:white;
  font-size: 16px; 


  flex-direction: column;
  margin-top:30px;
  display: flex;
  justify-content:space-between;
}

 
.mobile .footer .navbar{
  color:white;
  flex-direction: row;
}

.mobile .footer .navbar .navbar-item{
  color:white;
  font-size: 16px;
  margin-right: 20px;
}



