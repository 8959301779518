body {
  background: #000000;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 


  /* background-image: url(https://tva1.sinaimg.cn/large/e6c9d24ely1h3u115ro6sj202s02s3ya.jpg);

  background:url(https://tva1.sinaimg.cn/large/e6c9d24ely1h3u18ssq7fg208c08ct8s.gif);

  background:url(https://tva1.sinaimg.cn/large/e6c9d24ely1h3u14z8928g202s02s0qp.gif);
  background-repeat: repeat; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
